import React from "react"
import './src/styles/global.css';
import './src/styles/slider.css';
import './src/styles/forms.css';
import './src/styles/menu.css';
import './src/styles/hotspot.css';
import './src/styles/mobile.css';

import AuthProvider from "./src/components/Auth/AuthContext";

export const wrapRootElement = ({ element }) => (
    <AuthProvider >
        {element}
    </AuthProvider>
);