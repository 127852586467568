import { initializeApp } from "firebase/app";
import {
  getAuth,
  onAuthStateChanged,
} from "firebase/auth";
import { getFirestore } from "firebase/firestore";

const firebaseConfig = {
  apiKey: process.env.GATSBY_FIREBASE_API_KEY ?? "AIzaSyCuwMXwUahvqndenR7urQv_x-PGrULFsvM",
  authDomain: process.env.GATSBY_FIREBASE_AUTH_DOMAIN ?? "fir-1d3a6.firebaseapp.com",
  databaseURL: process.env.GATSBY_FIREBASE_DATABASE_URL ?? "https://fir-1d3a6.firebaseio.com",
  projectId: process.env.GATSBY_FIREBASE_PROJECT_ID ?? "fir-1d3a6",
  storageBucket: process.env.GATSBY_FIREBASE_STORAGE_BUCKET ?? "fir-1d3a6.appspot.com",
  messagingSenderId: process.env.GATSBY_FIREBASE_MESSAGING_SENDER_ID ?? "638248988588",
  appId: process.env.GATSBY_FIREBASE_APP_ID ?? "1:638248988588:web:dd1ca5d38dffc292dd1409",
  measurementId: process.env.GATSBY_FIREBASE_MEASUREMENT_ID ?? "",
};

let app
let auth
let db

if (typeof window !== "undefined") {
  app = app || initializeApp(firebaseConfig);
  auth = getAuth(app);
  db = getFirestore(app);
}

export { auth, onAuthStateChanged, db };